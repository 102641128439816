import React, { useState } from 'react';
import { IResourceComponentsProps, BaseRecord } from '@refinedev/core';
import {
  useTable,
  List,
  EditButton,
  DeleteButton,
  CreateButton,
  ShowButton,
} from '@refinedev/antd';
import { Table, Space } from 'antd';
import { Search } from '../../components/Search';
import { TierTypeEnum } from '../../types';
import { ExportCsvButton } from '../../components/buttons/CustomExportCsvButton';

export const TierList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  const [isEmptySearch, setIsEmptySearch] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<any>([]);

  const handleEmptySearch = () => {
    setIsEmptySearch(true);
  };

  if (searchResults?.length > 0) {
    tableProps.dataSource = searchResults;
  } else if (isEmptySearch) {
    tableProps.dataSource = [];
  }

  const paginationConfig =
    tableProps.dataSource !== undefined && searchResults.length > 0
      ? {
          total: searchResults.length > 0 ? searchResults.length : undefined,
        }
      : undefined;
  const mapEnumValue = (enumValue: string) => {
    switch (enumValue) {
      case 'visa':
        return TierTypeEnum.Visa;
      case 'master_card':
        return TierTypeEnum.MasterCard;

      case 'meeza':
        return TierTypeEnum.Meeza;
      default:
        return enumValue;
    }
  };

  return (
    <List
      headerButtons={[
        <ExportCsvButton key="export" path="tier" />,
        <CreateButton key="create" />,
      ]}
    >
      <Search
        path="tier"
        setSearchResults={setSearchResults}
        searchResults={searchResults}
        onEmptySearch={handleEmptySearch}
        onCancelSearch={() => setIsEmptySearch(false)}
      />
      <Table {...tableProps} rowKey="id" pagination={paginationConfig}>
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column
          dataIndex={['type']}
          title="Scheme"
          render={(value: string) => mapEnumValue(value)}
        />

        <Table.Column dataIndex={['created_by', 'name']} title="Created By" />

        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              {record.is_deleted ? (
                <DeleteButton hideText size="small" disabled />
              ) : (
                <DeleteButton hideText size="small" recordItemId={record.id} />
              )}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

export default TierList;
