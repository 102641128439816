import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import App from './App';
import './i18n';
import { BrowserTracing } from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

Sentry.init({
  dsn: 'https://bbfddc42a3d0d3c684cce5324e0149a5@sentry.wtmsrv.com/48',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  beforeSend(event) {
    event.contexts = {
      ...event.contexts,
      'State (localStorage)': {
        'Configs/store': window.localStorage.getItem('Configs/store'),
        'Account/store': window.localStorage.getItem('Account/store'),
      },
    };
    return event;
  },
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  normalizeDepth: 10,
  environment: window.location.host.includes('loynova-loyalty.dashboard.com')
    ? 'production'
    : window.location.host.includes(
          'loynova-loyalty.dashboard.staging.wtmsrv.com'
        )
      ? 'staging'
      : 'development',
});
const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <React.Suspense fallback="loading">
      <App />
    </React.Suspense>
  </React.StrictMode>
);
