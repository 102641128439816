import { Action, IResourceItem } from '@refinedev/core';
import React from 'react';
export type AxiosHeaders = {
  [x: string]: any;
  defaults: {
    headers: {
      api_key?: string;
      Authorization?: string;
      device_id?: string;
      Configs_id?: number;
    };
  };
};

export enum OsEnum {
  ios = 'ios',
  android = 'android',
  browser = 'browser',
}
export enum Language {
  ar = 'ar',
  en = 'en',
}

export enum AccountTypeEnum {
  admin = 'admin',
}

export type IAccount = {
  id: number;
  SystemConfigID: number | null;
  facebook_id: string | null;
  google_id: string | null;
  apple_id: string | null;
  biometric_id: string | null;
  name: string;
  email: string;
  phone_number: string | null;
  country: string | null;
  street_address: string | null;
  city: string | null;
  state: string | null;
  password: string;
  dob: string | null;
  gender: string | null;
  verified: boolean;
  confirmed: boolean;
  confirm_token: string;
  reset_password: boolean | null;
  upload_id: number | null;
  is_deleted: boolean;
  created_at: string;
  updated_at: string | null;
  account_type: 'calculation_module_admin';
  card_holder_id: string | null;
  card_details: {
    CardTypeLogo: string;
    CardTypeName: string;
    CardholderID: string;
    CashEquivalent: string;
    CurrentTierName: string;
    ForeColor: string;
    IsGiftCard: string;
    NextTierName: string;
    NextTierNeededCash: string;
    NextTierNeededDays: number;
    NextTierNeededPoints: number;
    NextTierNoOfPoints: number;
    PointsEarned: string;
    PointsLag: string;
    WalletBalance: string;
    AddCardFlag: string;
    CardExpiryDate: string;
  } | null;
  ep_token: string | null;
  pin: string | null;
  tokens: {
    access_token: string;
    refresh_token: string;
  };
};

export type RefineLayoutThemedTitleProps = {
  collapsed: boolean;
  text?: string;
  icon?: any;
  wrapperStyles?: React.CSSProperties;
  iconCollapsed?: any;
};

export interface UserProfileCardProps {
  userName: string;
  userEmail: string;
  avatarSrc?: string;
  siderCollapsed: boolean;
}

export enum TierTypeEnum {
  Visa = 'visa',
  MasterCard = 'master_card',
  Meeza = 'meeza',
}

export type Params = {
  resource?: IResourceItem;
  action?: Action;
  params?: Record<string, string | undefined>;
};
